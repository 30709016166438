import { useState } from 'react'
import { useLogin } from '../hooks/useLogin'

export default function SignIn() {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const { error, login, success } = useLogin()

	const handleSubmit = e => {
		e.preventDefault()
		login(email, password)
	}

	return (
		<div className='flex flex-col mx-auto my-20 px-5 py-10 max-w-md rounded-sm bg-white shadow-md border border-gray-200'>
			<h2 className='text-2xl mb-3'>Login</h2>
			<form onSubmit={handleSubmit} className='flex flex-col'>
				<label className='flex flex-col space-y-1 my-2'>
					<span>email:</span>
					<input
						className='border p-1 rounded-md outline-none'
						type='email'
						required
						onChange={e => setEmail(e.target.value)}
						value={email}
					/>
				</label>
				<label className='flex flex-col space-y-1 my-2'>
					<span>password:</span>
					<input
						className='border p-1 rounded-md outline-none'
						type='password'
						required
						onChange={e => setPassword(e.target.value)}
						value={password}
					/>
				</label>
				{error && (
					<p className='text-sm text-red-800 text-center my-2'>{error}</p>
				)}
				{success && (
					<p className='text-sm text-green-500 text-center my-2'>{error}</p>
				)}
				<button className='px-7 py-1 rounded-md border-pink-500 border-2 self-center'>
					Login
				</button>
			</form>
			<button className='self-center text-gray-500 text-sm pt-5'>
				reset password
			</button>
		</div>
	)
}
