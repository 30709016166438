import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isAuthReady: false,
	userId: null,
	activeUser: {},
	adminDetails: {},
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		authReducer(state, action) {
			state.isAuthReady = true
			state.userId = action.payload
		},
		activeUserReducer(state, action) {
			state.activeUser = action.payload
		},
		adminReducer(state, action) {
			state.adminDetails = action.payload
		},
		signOutReducer(state) {
			state.activeUser = null
			state.userId = null
		},
	},
})

export const { adminReducer, authReducer, signOutReducer, activeUserReducer } =
	userSlice.actions

export default userSlice.reducer
