import React from 'react'
import EmptyOrder from '../components/EmptyOrder'

export default function Orders() {
	return (
		<div className='min-h-screen'>
			<div className='border p-2 m-5 bg-gray-100 border-pink-600 rounded-md items-center flex flex-col'>
				<h1 className='text-2xl'>Delivery Notice</h1>
				<p>Orders are dispatched weekdays</p>
				<p>between 08:00 and 16:00</p>
				<p>excl. weekends and holidays</p>
			</div>
			<EmptyOrder />
		</div>
	)
}
