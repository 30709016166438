import { useEffect, useState } from 'react'
import { useCollection } from '../hooks/useCollection'
import Product from '../components/Product'
import Modal from '../components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { getMyProducts, getProducts } from '../context/slices/product-slice'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../firebase/config'

export default function Store() {
	const { documents: products } = useCollection('products')
	const [shownProducts, setShownProducts] = useState([])
	const dispatch = useDispatch()
	const { open } = useSelector(state => state.products.isOpen.open)
	const group = useSelector(state => state.user.activeUser.group)
	const userId = useSelector(state => state.user.userId)

	const eliteGroup = products?.filter(item => item.isBeauty !== true)
	const makeupGroup = products?.filter(item => item.isShown)
	const salesGroup = products?.filter(item => item.isBeauty)

	useEffect(() => {
		if (products && group) {
			if (group === 'salesRep') {
				setShownProducts(salesGroup)
			} else if (group === 'elite') {
				setShownProducts(eliteGroup)
			} else if (group === 'makeup') {
				setShownProducts(makeupGroup)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [group, products, dispatch])

	useEffect(() => {
		if (userId) {
			;(async () => {
				let ref = collection(db, 'myProducts')
				ref = query(ref, where('userId', '==', userId))
				try {
					onSnapshot(ref, snapshot => {
						let results = []
						snapshot.docs.forEach(doc => {
							results.push({ id: doc.id, ...doc.data() })
						})
						dispatch(getMyProducts(results))
						dispatch(getProducts(shownProducts))
					})
				} catch (error) {
					console.log(error)
				}
			})()
		}
	}, [dispatch, shownProducts, userId])

	return (
		<div className='min-h-screen'>
			<div className='grid grid-cols-3 items-end p-1 space-x-1 space-y-1'>
				{shownProducts?.map(item => (
					<Product key={item.id} product={item} />
				))}
			</div>
			{open && <Modal />}
		</div>
	)
}
