import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IoChevronForward } from 'react-icons/io5'
import { BiLogOut } from 'react-icons/bi'
import Switch from 'react-switch'
import { useLogout } from '../hooks/useLogout'

export default function Profile() {
	const user = useSelector(state => state.user.activeUser)
	const [group, setGroup] = useState()
	const [checked, setChecked] = useState(user.active)
	const { logout } = useLogout()

	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	]
	const date = new Date()
	const currentMonth = date.getMonth()

	useEffect(() => {
		if (user.group === 'elite') {
			setGroup('Elite sales')
		} else if (user.group === 'makeup') {
			setGroup('Makeuppreneur')
		} else if (user.group === 'salesRep') {
			setGroup('Sales Rep')
		} else {
			setGroup('Not assigned')
		}
	}, [user])

	const handleChange = () => {
		setChecked(!checked)
	}

	return (
		<div className='min-h-screen'>
			<div className='w-full items-center bg-gray-200 flex flex-col h-fit'>
				<div className='border-2 border-pink-500 w-36 h-36 mx-auto rounded-full my-5'>
					<img
						src={user.profilePic}
						alt='profile-pic'
						className='rounded-full'
					/>
				</div>
				<p>{user?.fullName}</p>
				<p>{group}</p>
				<div className='w-9/12 m-5 border-y-2 py-2 flex justify-between border-gray-100'>
					<div className='flex-col flex items-center'>
						<p className='text-xl'>Ranking</p>
						<p>Newbie</p>
					</div>
					<div className='flex-col flex items-center'>
						<p className='text-xl'>{`${months[currentMonth]} Orders`}</p>
						<p>R 0</p>
					</div>
				</div>
			</div>
			<div className=''>
				<div className='border-b py-4 mx-3 flex justify-between items-center'>
					<p className='text-xl'>Notifications</p>
					<Switch onChange={handleChange} checked={checked} />
				</div>
				<div className='border-b p-2 mx-3 flex justify-between items-center'>
					<div className=''>
						<p className='text-xl'>Account Details</p>
						<p className='text-sm'>Profile information</p>
					</div>
					<IoChevronForward className='text-2xl' />
				</div>
				<div className='border-b p-2 mx-3 flex justify-between items-center'>
					<p className='text-xl'>Privacy</p>
					<p className='text-sm'>T's and C's</p>
				</div>
				<div className='border-b p-2 mx-3 flex justify-between items-center'>
					<p className='text-xl'>Get Assistance</p>
					<p className='text-sm'>FAQ and support</p>
				</div>
				<button
					onClick={logout}
					className='border-b px-3 py-2 flex w-screen justify-between items-center'>
					<p className='text-xl'>Logout</p>
					<BiLogOut className='text-2xl' />
				</button>
			</div>
		</div>
	)
}
