import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { adminReducer } from '../context/slices/user-slice'
import { useCollection } from '../hooks/useCollection'
import { useFirestore } from '../hooks/useFirestore'

export default function AddressConfirmation() {
	const dispatch = useDispatch()
	const navigation = useNavigate()
	const userAddress = useSelector(state => state.user.activeUser.address)
	const userId = useSelector(state => state.user.userId)
	const { documents } = useCollection('adminDetails')
	const { updateDocument } = useFirestore('userDetails')
	const [address, setAddress] = useState(userAddress)

	if (documents) {
		documents.map(item => dispatch(adminReducer(item)))
	}

	const handleSubmit = e => {
		e.preventDefault()
		if (userAddress !== address) {
			updateDocument(userId, { address })
		}
		navigation('/payment')
	}

	return (
		<div className='min-h-screen'>
			<div className='mx-auto items-center flex flex-col w-fit p-5 mt-5 rounded-md border-2 bg-gray-600 text-white'>
				<p>Your package will be delivered</p>
				<p>to the below listed address.</p>
			</div>
			<div className='flex items-center justify-center my-2 mx-5 border bg-gray-200 p-2'>
				<p>{address}</p>
			</div>
			<div className='text-sm items-center flex flex-col'>
				<p>Please confirm if the address is correct,</p>
				<p>else provide us with an address.</p>
				<form onSubmit={handleSubmit}>
					<input
						type='text'
						className='border-2 border-yellow-500 p-2 my-3 bg-slate-100 rounded-md'
						value={address}
						required
						onChange={e => setAddress(e.target.value)}
					/>
					<button className='submitBtn'>Confirm address</button>
				</form>
			</div>
		</div>
	)
}
