import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from '../context/slices/product-slice'
import { useFirestore } from '../hooks/useFirestore.js'

export default function Modal() {
	const { content } = useSelector(state => state.products.isOpen.content)
	const myProducts = useSelector(state => state.products.myProducts)
	const userId = useSelector(state => state.user.userId)
	const {
		minQuantity,
		price,
		descQuantity,
		unitPrice,
		discPrice,
		image,
		size,
		name,
		id,
	} = content
	const [quantity, setQuantity] = useState(minQuantity)
	const { addDocument } = useFirestore('myProducts')
	const dispatch = useDispatch()

	const addQuantity = () => {
		setQuantity(prevState => prevState + 1)
	}
	const removeQuantity = () => {
		if (quantity > 1) {
			setQuantity(prevState => prevState - 1)
		}
	}
	const addProduct = () => {
		const existingItemID = myProducts.find(item => item.productID === id)
		if (existingItemID) return
		addDocument({
			userId: userId,
			productID: id,
			quantity: quantity,
		})
		dispatch(openModal({ open: false, content: null }))
	}

	const calculateDiscount = () => {
		if (quantity === minQuantity) {
			return parseInt(price * minQuantity)
		}
		if (quantity > minQuantity && quantity < descQuantity) {
			return parseInt(price * quantity)
		}
		if (quantity < minQuantity) {
			return parseInt(quantity * unitPrice)
		}
		if (quantity >= descQuantity) {
			const discountQuantity = discPrice ? discPrice : price
			return parseInt(quantity * discountQuantity)
		}
	}

	return (
		<div className='modalOverlay'>
			<div className='modalCover'>
				<div
					onClick={() => dispatch(openModal({ open: false, content: null }))}
					className='absolute right-7 w-10 bg-gray-200 flex items-center justify-center rounded-full shadow-md h-10 border'>
					<IoClose className='text-xl' />
				</div>
				<div className='bg-slate-300 rounded-t-md flex items-center justify-center'>
					<img src={image} alt='product' className='p-5' />
				</div>
				<div className='flex-col justify-start flex p-2'>
					<div className='text-xl'>{name}</div>
					<div className='text-sm'>{size}</div>
					<div className='flex py-2 justify-evenly items-center'>
						<p className='text-xl'>R {calculateDiscount()}</p>
						<div className='flex border-2 bg-gray-100 py-1 px-3 rounded-full text-lg justify-between'>
							<button onClick={removeQuantity} className='w-10'>
								-
							</button>
							<p>{quantity}</p>
							<button onClick={addQuantity} className='w-10'>
								+
							</button>
						</div>
						<button
							onClick={addProduct}
							className='bg-pink-500 text-white px-3 py-1 rounded-xl'>
							add
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
