import React from 'react'
import { IoAdd } from 'react-icons/io5'
import EmptyChat from '../components/EmptyChat'

export default function Messages() {
	return (
		<div className='min-h-screen'>
			<button className='bottom-20 absolute bg-pink-500 h-24 rounded-full w-24 items-center justify-center flex right-5'>
				<IoAdd className='text-6xl text-pink-800' />
			</button>
			<EmptyChat />
		</div>
	)
}
