import { Link } from 'react-router-dom'
import React from 'react'
import { RiAccountBoxFill } from 'react-icons/ri'
import {
	IoReceipt,
	IoStorefrontSharp,
	IoChatboxEllipses,
} from 'react-icons/io5'
import { useSelector } from 'react-redux'

export default function Footer() {
	const user = useSelector(state => state.user.userId)
	return (
		<div className='z-40 flex sticky text-xs flex-col w-screen border-t bg-gray-100 justify-center bottom-0 items-center p-2 mt-3'>
			{user && (
				<div className='flex space-x-5 align-bottom text-gray-500 w-full justify-evenly'>
					<Link to='/' className='flex flex-col items-center'>
						<IoStorefrontSharp className='text-2xl' />
						Store
					</Link>
					<Link to='/messages' className='flex flex-col items-center'>
						<IoChatboxEllipses className='text-2xl' />
						Message
					</Link>
					<Link to='/orders' className='flex flex-col items-center'>
						<IoReceipt className='text-2xl' />
						Orders
					</Link>
					<Link to='/profile' className='flex flex-col items-center'>
						<RiAccountBoxFill className='text-2xl' />
						Profile
					</Link>
				</div>
			)}
			{!user && <p className='text-gray-500'>version 2.0</p>}
		</div>
	)
}
