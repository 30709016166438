import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	packaging: false,
	amount: null,
	content: null,
	selectProduct: {
		product: {},
		openModal: false,
	},
	orderItems: null,
}

const orderSlice = createSlice({
	initialState,
	name: 'order',
	reducers: {
		getPackaging(state, action) {
			state.packaging = action.payload
			state.amount = action.payload
			state.content = action.payload
		},
		getSelectedItem(state, action) {
			state.selectProduct = {
				product: action.payload,
				openModal: action.payload,
			}
		},
		finalOrderItems(state, action) {
			state.orderItems = action.payload
		},
	},
})

export const { getPackaging, getSelectedItem, finalOrderItems } =
	orderSlice.actions
export default orderSlice.reducer
