import { Link } from 'react-router-dom'
import logo from '../assets/logo.png'
import { MdShoppingBasket } from 'react-icons/md'
import { useSelector } from 'react-redux'

export default function Navbar() {
	const products = useSelector(state => state.products.myProducts)
	const user = useSelector(state => state.user.userId)

	return (
		<div className='z-50 bg-gray-50 flex sticky top-0 justify-between border-b items-center h-12 px-2 mt-3'>
			<Link to='/' className='flex items-center'>
				<img
					src={logo}
					alt='havillah logo'
					className=' mr-2 w-10 rounded-full'
				/>
				<p className='font-semibold'>Havillah Elite</p>
			</Link>
			<div className='space-x-4'>
				{user && (
					<Link to='/cart' className='rounded-md top-3 absolute right-5'>
						<MdShoppingBasket className='text-2xl' />
						<span className='relative text-xs px-1 -top-10 left-5 bg-fuchsia-400 rounded-full '>
							{products.length}
						</span>
					</Link>
				)}
			</div>
		</div>
	)
}
