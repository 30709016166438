import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Cart from './pages/Cart'
import Store from './pages/Store'
import Orders from './pages/Orders'
import SignIn from './pages/SignIn'
import Profile from './pages/Profile'
import Messages from './pages/Messages'
import NavBar from './components/Navbar'
import Footer from './components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { auth, db } from './firebase/config'
import { doc, getDoc } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'
import AddressConfirmation from './pages/AddressConfirmation'
import { authReducer, activeUserReducer } from './context/slices/user-slice'
import Payment from './pages/Payment'

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const isAuthReady = useSelector(state => state.user.isAuthReady)
	const [isLoading, setIsLoading] = useState(true)
	const dispatch = useDispatch()

	useEffect(() => {
		const subscribe = onAuthStateChanged(auth, user => {
			if (user && user.emailVerified) {
				try {
					dispatch(authReducer(user.uid))
					const docRef = doc(db, 'userDetails', user.uid)
					const fetchData = async () => {
						const docData = await getDoc(docRef)
						dispatch(activeUserReducer(docData.data()))
					}
					fetchData()
					setIsAuthenticated(true)
					setIsLoading(false)
				} catch (error) {
					console.log(error.message)
					setIsLoading(false)
					return
				}
			} else {
				setIsAuthenticated(false)
				setIsLoading(false)
			}
		})
		return subscribe
	}, [dispatch])

	if (isLoading) {
		;<h1>Loading...</h1>
	}

	return (
		<BrowserRouter>
			{isAuthReady && (
				<>
					<NavBar />
					<Routes>
						<Route
							path='/'
							element={isAuthenticated ? <Store /> : <Navigate to='/login' />}
						/>
						<Route
							path='/login'
							element={isAuthenticated ? <Navigate to='/' /> : <SignIn />}
						/>
						<Route
							path='/messages'
							element={
								isAuthenticated ? <Messages /> : <Navigate to='/login' />
							}
						/>
						<Route
							path='/orders'
							element={isAuthenticated ? <Orders /> : <Navigate to='/login' />}
						/>
						<Route
							path='/profile'
							element={isAuthenticated ? <Profile /> : <Navigate to='/login' />}
						/>
						<Route
							path='/cart'
							element={isAuthenticated ? <Cart /> : <Navigate to='/login' />}
						/>
						<Route
							path='/address'
							element={
								isAuthenticated ? (
									<AddressConfirmation />
								) : (
									<Navigate to='/login' />
								)
							}
						/>
						<Route
							path='/payment'
							element={isAuthenticated ? <Payment /> : <Navigate to='/login' />}
						/>
						<Route
							path='/*'
							element={
								isAuthenticated ? <Navigate to='/' /> : <Navigate to='/login' />
							}
						/>
					</Routes>
					<Footer />
				</>
			)}
		</BrowserRouter>
	)
}

export default App
