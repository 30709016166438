import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { BsFileEarmarkImage } from 'react-icons/bs'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { storage, timestamp } from '../firebase/config'
import { useFirestore } from '../hooks/useFirestore'
import { useNavigate } from 'react-router-dom'

const months = [
	'JANUARY',
	'FEBRUARY',
	'MARCH',
	'APRIL',
	'MAY',
	'JUNE',
	'JULY',
	'AUGUST',
	'SEPTEMBER',
	'OCTOBER',
	'NOVEMBER',
	'DECEMBER',
]

export default function Payment() {
	const adminDetails = useSelector(state => state.user.adminDetails)
	const user = useSelector(state => state.user.activeUser)
	const userId = useSelector(state => state.user.userId)
	const order = useSelector(state => state.order.orderItems)
	const navigation = useNavigate()
	const { addDocument } = useFirestore('orders')
	const { deleteDocument } = useFirestore('myProducts')
	const [thumbnailError, setThumbnailError] = useState(null)
	const [selectedImage, setSelectedImage] = useState(null)
	const [previewImage, setPreviewImage] = useState(null)
	const [formError, setFormError] = useState(null)
	const day = new Date().getDate()
	const year = new Date().getFullYear()
	const monthNr = new Date().getMonth()
	const month = new Date().getUTCDay()
	const { accountHolder, accountNumber, bankName, branchCode, type } =
		adminDetails
	const orderNumber = user.fullName.slice().toLowerCase().trim() + day + month
	const dateCreated = `${day} ${months[monthNr]} ${year}`

	const { bagTotal, branding, cartContent, listedItems } = order

	const handleSubmit = e => {
		e.preventDefault()
		setFormError(null)

		const obj = {
			dateCreated: dateCreated,
			monthCreated: month,
			yearCreated: year,
			orders: cartContent,
			bagTotal: bagTotal,
			branding: branding,
			orderNumber: orderNumber,
			userID: userId,
			randomUserID: user.randomUserID,
			stage: 1,
			createdAt: timestamp,
			timeCreated: Date.now(),
			timestamp: timestamp,
			paymentProof: selectedImage,
			status: 'Pending',
			confirmed: false,
			address: user.address,
			listedItems: listedItems,
		}
		if (!selectedImage) {
			setThumbnailError('Please select an image')
			return
		}
		console.log(obj)
		try {
			addDocument(obj)
				.then(() =>
					cartContent.forEach(item => {
						deleteDocument(item.firestoreId)
					})
				)
				.finally(() => navigation('/'))
		} catch (error) {
			setFormError(error.message)
		}
	}
	const handleFileChange = async e => {
		setSelectedImage(null)
		setPreviewImage(null)
		let selected = e.target.files[0]

		if (!selected) {
			setThumbnailError('Please select an image')
			return
		}
		if (selected.size > 1100000) {
			setThumbnailError('Image size must be less than 1MB')
			return
		}

		const imagePathRef = ref(
			storage,
			`payments/${selected.name + new Date().getMilliseconds()}`
		)
		await uploadBytesResumable(imagePathRef, selected)
		const url = await getDownloadURL(imagePathRef)
		setSelectedImage(url)

		setThumbnailError(null)
		const reader = new FileReader()
		reader.addEventListener('load', () => {
			setPreviewImage(reader.result)
		})
		reader.readAsDataURL(selected)
	}

	return (
		<div className='min-h-screen'>
			<div className='text-white p-5 flex justify-center bg-pink-500'>
				<p>Attach Proof of Payment</p>
			</div>
			<div className='px-5 py-2 flex flex-col'>
				<p className='text-xl mb-2'>Havillah Banking Details</p>
				<div className='border-y justify-between py-1 flex'>
					<p>Account Holder : </p>
					<p>{accountHolder}</p>
				</div>
				<div className='border-y justify-between py-1 flex'>
					<p>Bank Name : </p>
					<p>{bankName} </p>
				</div>
				<div className='border-b justify-between py-1 flex'>
					<p>Account Type : </p>
					<p>{type} </p>
				</div>
				<div className='border-b justify-between py-1 flex'>
					<p>Branch Code : </p>
					<p>{branchCode} </p>
				</div>
				<div className='border-b justify-between py-1 flex'>
					<p>Account Number : </p>
					<p>{accountNumber} </p>
				</div>
				<div className='border-b justify-between py-1 flex'>
					<p>Reference : </p>
					<p>{orderNumber} </p>
				</div>
			</div>
			<div className='p-2 flex flex-col items-center bg-gray-400'>
				<p className='text-sm'>Amount payable</p>
				<p className='text-2xl'>R {bagTotal}</p>
			</div>
			<form
				onSubmit={handleSubmit}
				className='flex flex-col items-center w-full'>
				<div className='w-36 h-36 rounded-full m-5 p-3 flex-col items-center justify-center flex bg-pink-500'>
					{previewImage ? (
						<img src={previewImage} alt='' />
					) : (
						<>
							<BsFileEarmarkImage className='text-5xl text-green-400 my-1' />
						</>
					)}
				</div>
				<label className='text-sm items-center px-3 w-full flex flex-col'>
					<span>Attach proof of payment:</span>
					<input type='file' required onChange={handleFileChange} />
				</label>
				{thumbnailError && (
					<p className='text-xs text-red-500 my-2'>{thumbnailError}</p>
				)}
				{formError && <p className='text-xs text-red-500 my-2'>{formError}</p>}
				<button className='submitBtn'>Submit</button>
			</form>
		</div>
	)
}
